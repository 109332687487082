import React from 'react';
import styled, { css } from 'styled-components';

import { Section } from 'src/components/molecules/Section';
import { Grid } from 'src/components/molecules/Grid';
import { Link } from 'src/containers/Link';
import { HighlightedText } from 'src/components/atoms/HighlightedText';

const Container = styled(({ to, ...props }) => (to ? <Link to={to} {...props} /> : <div {...props} />))`
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  box-shadow: 0 9px 22px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  background-color: white;
  font-size: 0.875rem;

  &:hover,
  &:active {
    text-decoration: none;
    box-shadow: 0 14px 28px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-0.25rem);
  }

  ${({ to }) =>
    to &&
    css`
      cursor: pointer;
    `}

  a {
    color: inherit;
    text-decoration: underline;
  }
`;

const TeaserTitle = styled.h3`
  margin-bottom: 0.5rem;
`;

const TeaserContent = styled.div`
  padding: 1.25rem;
  flex: 1;
  color: #6d708b;
`;

const TeaserFooter = styled.div`
  padding: 0 1.25rem 1.25rem;
  font-weight: bold;
`;

export default function Drawer({ heading, callouts }) {
  return (
    <Section centered maxWidth="large">
      <h2>{heading}</h2>

      <Grid columns="1fr 1fr 1fr">
        {callouts.map((callout, i) => {
          const { preHeading, heading, ctas } = callout;
          const cta = ctas[0];
          return (
            <Container key={i} to={cta?.url}>
              <TeaserContent>
                <HighlightedText margin>{preHeading}</HighlightedText>
                <TeaserTitle>{heading}</TeaserTitle>
              </TeaserContent>
              <TeaserFooter>{cta?.text}</TeaserFooter>
            </Container>
          );
        })}
      </Grid>
    </Section>
  );
}
